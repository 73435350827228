import { createContext, useState, useEffect } from "react";

export const WalletContext = createContext();

const {ethereum} = window;

export const WalletContextProvider = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState("");

  const checkWalletIsConnected = async () => {
    try {
        
        if(!ethereum) return alert("Please install metamask");

        const accounts = await ethereum.request({ method: 'eth_accounts'});

        if(accounts.length) {
            setCurrentAccount(accounts[0]);
        }
        else {
            console.log("No accounts found")
        }
    } catch (error) {
        console.log(error);

        throw new Error("No ethereum object")
    }
}

    const connectWallet = async () => {
        try {
            if(!ethereum) return alert("Please install metamask");

            const accounts = await ethereum.request({ method: 'eth_requestAccounts'});
            setCurrentAccount(accounts[0]);
        }
        catch (error) {
            console.log(error);

            throw new Error("No ethereum object")
        }
    }

    
    useEffect(() => {
        checkWalletIsConnected();
    }, [])

    // Detect change in Metamask account
    useEffect(() => {
        
        if (window.ethereum) {
            window.ethereum.on("chainChanged", (chainId) => {
                window.location.reload();
            });
            window.ethereum.on("accountsChanged", () => {
                window.location.reload();
            });
        }
        if (window.ethereum) {
            return () => {
                window.ethereum.removeListener('chainChanged',  () => {
                window.location.reload();
            });
                window.ethereum.removeListener('accountsChanged',  () => {
                window.location.reload();
            });
            }
        }
        
    }, []);


    return(
        <WalletContext.Provider value={{connectWallet, currentAccount}}>
            {children}
        </WalletContext.Provider>
    )
}