/* eslint-disable jsx-a11y/anchor-is-valid */
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Main from './components/hero';
import { Navbar } from "./components/navbar"
import Roadmap from './components/roadmap';
import Mint from './components/mint/Mint';

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { mainnet } from 'wagmi/chains'

const chains = [mainnet]
const projectId = '8e96733c2e56867517594de060bf5318'

const { provider } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider
})
const ethereumClient = new EthereumClient(wagmiClient, chains)


function App() {

  return (
    
    <div>
      <WagmiConfig client={wagmiClient}>

        <Routes>
          <Route exact path="/" element={
            <div>
                <Navbar/>
                <Main/>
                <Roadmap/>    
            </div>}/>
            <Route path='/mint' element={
              <>
                <Navbar/>
                <Mint/>
              </>
              
            }/>
        </Routes>
      </WagmiConfig>

      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} themeVariables={{
                '--w3m-font-family': 'Roboto, sans-serif',
                '--w3m-accent-color': '#654A20',
                "--w3m-background-color": "#654A20",
                "--w3m-accent-fill-color": "#FFFFFF"
              }}/>


    </div>
    
    
    
  );
}

export default App;

