export const FaqData = [
    {
      question: "Q:  What is Teach em Turtles?",
      answer:
        " The Teach'em Turtles are a unique set of life-like turtles with hand drawn attributes. membership, to belong to a community which focuses on educating as well as participating in leading the community as a community based project. We are also an ecosystem friendly project with goals of helping the environment",
    },
    {
      question: "Q:  How to be eligible for phase 1 mint?",
      answer:
        "To be eligible you have to be part of the community in the discord as well as on twitter. Those who have WL or OG roles will be added to the allowlist for the mint passes which will be burned to get the nfts form the first phase.",
    },
    {
      question: "Q:  What is The Turtle's Pass?",
      answer:
        " The Turtles pass is a free mint for only OG & WL role holders. These passes will have a burn mechanism for minting the actual NFTs from phase 1.The turtle's free mint passes will be available to mint 2-3 weeks prior the mint date of the phase 1 mint.",
    },
    {
      question: "Q:   When is Phase 1 mint? (EST)",
      answer:
        " 22nd March Time tbd, our discord will have all the necessary updates on the mint.",
    },
    {
      question: "Q:  What are the Teach Em Turtle Events?",
      answer:
        "We do poker events based on community collabs with other projects every Friday, as well as poker within our own community. IRL Events for beach clean ups, planting trees with the founders every 3-4weeks.",
    },
    {
      question: "Q: What is Teach Em Verse?",
      answer:
        "The Teach Em Verse is a 3 floor decentraland compound, with the first floor as <br/> -The NFTs Museum, integrated market place <br/> - Decentralabz Trading Firm. This floor is for our financial education centre, team trading history, trade setups for the week. Only accessible to holders. <br/>- The club is mostly for online gambling with crypto currency. The interior design is based on the movie “Harlem nights” <br/>-Rooftop  (Sector 304) This section is a top tier Gentleman's club with a glass ceiling. The floor is open spaced with a bar on the left.", 
    },
    {
      question: "Q: Funds allocation post mint for the community wallet?",
      answer:
        "Creator fee 2% <br/> Compound revenue 25% <br/> Mint percentage 10% <br/> Passive income also to those who are holders of 5 Teach Em Turtles NFTs.",
    },
    
  ];
  