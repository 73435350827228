import { useState, useContext, useEffect } from "react";
import { ethers } from "ethers";
import { WalletContext } from "../../context/WalletContextProvider";
import { Web3Button } from '@web3modal/react'

const abi = require("./abi.json");

export default function Mint() {

  const {currentAccount, connectWallet} = useContext(WalletContext);
  const [proof, setProof] = useState(null);

  let signer;
  console.log(proof)

  if (currentAccount != "") {
    signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();
  }

  const [counter, setCounter] = useState(0);

  const increase = () => {
    setCounter((count) => count + 1);
  };

  //decrease counter
  const decrease = () => {
    setCounter((count) => count - 1);
  };

//   const mintOG = async () => {
//     if (counter == 0 || counter > 5) {
//       alert("Please select a quantity from 1 to 5");
//       return;
//     }
//       else {
//         const turtles = new ethers.Contract("0xc24ce76a44ed50325843aebd0838daebc19a8558", abi, signer)
//         await turtles.mint(proof.OG, 0, counter, {value: ethers.utils.parseEther(String(0.015 * counter))})
//       }
//   }

//   const mintWL = async () => {
//     if (counter ==  0 || counter > 3) {
//       alert("Please select a quantity from 1 to 3");
//       return;
//     }
//       else {

//         const turtles = new ethers.Contract("0xc24ce76a44ed50325843aebd0838daebc19a8558", abi, signer)
//         await turtles.mint(proof.WL, 1, counter, {value: ethers.utils.parseEther(String(0.0175 * counter))})
//       }
// }

  const mintPublic = async () => {
        const turtles = new ethers.Contract("0xc24ce76a44ed50325843aebd0838daebc19a8558", abi, signer)
        await turtles.mint([], 2, counter, {value: ethers.utils.parseEther(String(0.02 * counter))})
}

  // useEffect(() => { 
  //   const getProof = async () => {
  //     const response = await fetch("https://merkle-turtle.vercel.app/getProof/" + currentAccount);
  //     setProof(await response.json())
  //   }
  //   if (currentAccount != "") getProof();

  // }, [currentAccount])

  return (
    <div
      data-aos="fade-up"
      data-aos-duration="1000"
      className="max-w-[1500px] w-[80%] mx-auto my-20"
    >
      
        <div className="text-center pt-5 lg:pt-0">
         
         {/* MINT PASS */}
         
          <div style={{display: "flex",  alignItems: "center", justifyContent: "center", flexDirection: "column", marginTop: "15vh"}}>
            <img  src="./assets/turtle.svg" alt="Mint Turtle" />

            <h1 className="batsand" style={{fontSize: "60px", transform: "translate(0, 60px)"}}> Phase 1 Mint  </h1>
            <h1 className="batsand-dark" style={{fontSize: "60px", marginBottom: "5vh"}}> Phase 1 Mint   </h1>
            <p className="font-normal mint-text  text-white" style={{fontSize: "23px"}}>
            Phase 1 Teach Em Turtles. The Early supporters of <br /> 
            the project. Holders get access to the teach em <br /> 
            turtles eco system. 
            </p>

      
        {/* PLUS MINUS BUTTON */}

          <div className=" border rounded-full  p-1  w-[288px] mx-auto mt-10">
            <div className="flex justify-between items-center   py-4 px-4 rounded-full">
              <button
                disabled={counter <= 0}
                className="cursor-pointer "
                onClick={decrease}
              >
                <img src="./assets/minus.svg" alt="minus" />
              </button>
              <div className="text-2xl ">{counter}</div>
              <button className="cursor-pointer " onClick={increase}>
                <img src="./assets/plus.svg" alt="sum" />
              </button>
            </div>
          </div> 


          {/* first wl query */}
           {/* <div className="flex justify-around items-center mx-auto pt-5">
            <div>
              <img src="./assets/eva_info.svg" alt="eva_info" />
            </div>
            <div>
              <p className="text-base leading-[24px] font-medium text-[#654A20]">
              3 NFTS per WL Wallet
              </p>
              
            </div>
          </div> */}
          
          {/* second wl query */}

          <div className="flex justify-around items-center mx-auto pt-5">
            <div>
              <img src="./assets/eva_info.svg" alt="eva_info" />
            </div>
            <div>
              <p className="text-base leading-[24px] font-medium text-[#654A20]">
              1 transaction per wallet
              </p>
              
            </div>
          </div>


              {/* first mint button */}

          <div
            onClick={ currentAccount != "" ? mintPublic : () => alert("Connect wallet")}
            className="cursor-pointer bg-[#654A20] rounded-full mx-auto p-2 mt-5"
            style={{width: "120px", marginTop: "3vh"}}
          >
            <p className="text-lg font-bold text-white 2xl:top-[60%]">Mint Public</p>
          </div>

              {/* second mint button */}


          {/* <div
            onClick={ currentAccount == "" ? connectWallet : proof != null && proof.OG.length > 0 ? mintOG : () => alert("You are not eligible")}
            className="cursor-pointer bg-[#654A20] rounded-full  w-[15%] mx-auto p-2 mt-5"
            style={{width: "120px"}}
          >
            <p className="text-lg font-bold text-white 2xl:top-[60%]">Mint OG</p>
          </div> */}

          <div className="flex justify-evenly items-center mx-auto mt-5" style={{marginTop: "5vh", width: "400px"}}>
            <a href="https://etherscan.io/token/0xc24ce76a44ed50325843aebd0838daebc19a8558">
              <img
                src="./assets/etherscan_circle.svg"
                alt="etherscan_circle"
                style={{width: "40px"}}
              />
            </a>
            <a>
              <img src="./assets/eth.svg" alt="eth" style={{width: "40px"}}/>
            </a>
            <a>
              <img src="./assets/mintwit.svg" alt="mintwit"  style={{width: "40px"}}/>
            </a>
            <a href="https://discord.gg/teachemturtles" >
              <img src="./assets/mintbrowser.svg" alt="mintbrowser" style={{width: "40px"}}/>
            </a>
          </div>
          </div>

          {/* MINT PASS SUPPLY */}
          
          




         
        </div>
        
        <div style={{position: "fixed", right: "1vw", top: "3vh", zIndex: 100}}>
            <Web3Button />
        </div>
      </div>
      
  );
}
